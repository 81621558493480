<template>
  <div style="margin-left: 20px; margin-right: 20px">
    <a-page-header
      style="border: 1px solid rgb(235, 237, 240)"
      title="Назад"
      @back="() => $router.go(-1)"
    />
    <a-table
      style="background-color: white"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :row-key="(record) => record.id"
    >
      <template v-slot:action="text">
        <a-tooltip placement="topRight" title="Удалить документ из пакета"
          ><a-button @click="deleteDocumentFromPackage(text.id)"
            ><a-icon type="delete" /></a-button
        ></a-tooltip>
      </template>
    </a-table>
    <div>
      <a-form style="width: 500px">
        <a-form-item label="Добавить новый документ в пакет">
          <a-textarea
            v-model="document_title"
            placeholder="Название документа"
          ></a-textarea>
          <a-button @click="addDocumentInPackage">Добавить</a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

const columns = [
  {
    title: "id",
    dataIndex: "id",
    width: 70,
  },
  {
    title: "Название",
    dataIndex: "title",
  },
  {
    title: "",
    key: "action",
    scopedSlots: { customRender: "action" },
    width: 50,
  },
];

export default {
  props: ["documentId"],
  data() {
    return {
      columns,
      data: [],
      document_name: "",
      documents: [],
      document_title: "",
      loading: false,
    };
  },
  created() {
    this.getDocumentPack();
  },
  methods: {
    getDocumentPack() {
      axios
        .get("/cp/packages/")
        .then((response) => {
          // filter
          let asd = response.data.data.find((o) => o.id === this.documentId);
          this.data = asd.docs;
          // this.document_name = my_document.name;
          console.log(this.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveDocuments() {
      console.log("save documents here");
    },
    deleteDocumentFromPackage(document_id) {
      axios
        .delete(`/cp/docs/${document_id}/`)
        .then((response) => {
          console.log(response);
          this.getDocumentPack();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addDocumentInPackage() {
      console.log("add document");
      let data = { title: this.document_title };
      axios
        .post(`/cp/packages/${this.documentId}/docs/`, data)
        .then((response) => {
          console.log(response);
          this.getDocumentPack();
          this.document_title = "";
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleSubmit(e) {
      e.preventDefault();
      console.log("save documents here");
    },
  },
};
</script>
